/* App Container Styles */
.App {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  overflow-y: hidden;
  align-items: center;
}

/* Logo Styles */
.Logo {
  width: 93px;
  margin-top: 50px;
}

/* Input Container Styles */
.input-container {
  position: relative;
  width: 342px;
  margin-top: 40px;
}

.input-container input {
  width: 313px;
  padding: 14px;
  border: 0.8px solid #E3E2E4;
  border-radius: 15px;
  background-color: #FAFAFA;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  outline: none;
  box-shadow: inset 0 0 0 #FAFAFA;
  transition: .18s ease-out;
}

.input-container label {
  position: absolute;
  top: -8px;
  left: 12px;
  background-color: #FAFAFA;
  padding: 0 4px;
  color: #7D7D7D;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  transition: 0.3s;
}

.input-container input::placeholder {
  color: #CDCDCD;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  opacity: 1;
}

/* Scrollable Content Container */
.scrollable-content {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.Tagline {
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  text-align: center;
  font-size: 14px;
  width: 300px;
  color: #979797;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: 'Inter', sans-serif;
  font-style: italic;
}

.Tagline.error {
  background-color: #F1DEDD;
  color: #A65858;
  border-radius: 5px;
  padding: 10px;
}

.song-list-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Line Divider Styles */
.line {
  border-top: 1px solid #EBEBEB;
  width: 200px;
  margin: 3px auto;
}

/* Song List Styles */
.seedsongs, .recommendations {
  width: 87.6%;
  margin: 10px auto;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  color: #000000;
}

.recommendations-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.recommendations {
  font-weight: 500;
  flex-grow: 1;
  overflow-y: auto; /* Changed from scroll to auto */
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
}

.CTA, .CTA_completed {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
  height: 40px;
  width: 342px;
  background-color: #000000;
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
}

.CTA_completed {
  background-color: #999999;
  pointer-events: none;
}

@keyframes loadingAnimation {
  0%, 80%, 100% { transform: scale(0.8); color: #000; }
  40% { transform: scale(1.2); color: #bbb; }
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
  animation: loadingAnimation 1.4s infinite ease-in-out both;
}

.loading-dot:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-dot:nth-child(2) {
  animation-delay: -0.16s;
}