ol {
  padding-left: 0;
}

ol > li {
  list-style: none;
  margin-bottom: 8px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.songInfo {
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  flex-grow: 1; /* Ensures that this part takes up the available space */
  width: 300px;
  white-space: nowrap; /* Prevents text from wrapping to a new line */
  overflow: hidden; /* Hides text that overflows the element's box */
  text-overflow: ellipsis; /* Adds an ellipsis when text overflows */
}

.deleteButton {
  cursor: pointer;
  border: none;
  background: none;
  color: #CDCDCD;
  padding-left: 0px;
  padding-right: 0px;
  min-width: 80px;
  text-align: right;
}