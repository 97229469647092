/* index.css */

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #FAFAFA; /* Set to your desired background color */
  overflow-x: hidden; /* Prevent horizontal overflow */
  overscroll-behavior: contain;
}

body {
  margin: 0;
  background-color: #FAFAFA; /* Ensure the root element has the same background color */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  background-color: #FAFAFA; /* Ensure the root element has the same background color */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'),
      url('/src/fonts/Inter-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter-Medium'),
      url('/src/fonts/Inter-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Italic'), local('Inter-Italic'),
      url('/src/fonts/Inter-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Bold'), local('Inter-Bold'),
      url('/src/fonts/Inter-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
